.ant-input-affix-wrapper {
  border-radius: 8px;
  padding: 8px 12px;
}

.revolve-input.ant-input {
  border-radius: 16px;
  padding: 18px 20px;
  // box-shadow: 0px 8px 12px -2px #0000000A !important;
  border: 1px solid #f1f1f1;
  background: #fff;
}

.revolve-input.ant-input-password {
  border-radius: 16px;
  padding: 18px 20px;
  // box-shadow: 0px 8px 12px -2px #0000000A !important;
  border: 1px solid #f1f1f1;
  background: #fff;
}

.ant-form.ant-form-vertical {
  .ant-form-item {
    margin-bottom: 14px;
  }

  .ant-form-item-label {
    padding: 0;
  }
}

.ant-select.revolve-select {
  width: 100%;
  min-height: 60px;

  .ant-select-selector {
    min-height: 38px;
    border-radius: 16px;
    // box-shadow: 0px 10px 16px -2px #0000000A !important;
    border: 1px solid #f1f1f1 !important;
    background: #fff;
    height: 100%;

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      line-height: 23px !important;

    }

    .ant-select-selection-search-input {
      // height: 100 !important;
    }
  }
}

.revolve-input.ant-picker {
  border-radius: 8px;
  padding: 8px 12px;
}

.treasury-multi-select {
  .ant-select-selector {
    min-height: 40px;
  }
}

.PhoneInputInput {
  outline: none !important;
  // outline: #E6FF00 !important;
}

.ant-row {
  display: flex;
  flex-direction: column;

  .ant-col {
    text-align: start;
  }
}

.ant-form-item-label>label::after {
  display: none;
}


.custom-input-class {
  // width: 72px !important;
  width: 50px !important;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #F1F1F1;
  margin-right: 8px;
  background: #fff;
  font-size: 16px;
  box-shadow: 0px 4px 8px -4px #0000000A;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #181B01 !important;
  border-color: #181B01 !important;
  color: #E6FF00 !important;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #E6FF00 !important;

}

.eligibility-form {
  min-width: 600px !important;
  padding: 24px;
  background: #fff;
  width: 100% !important;
  border-radius: 5px;
  border: 1px #41423E1A solid;

  .ant-typography {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.04em;
    text-align: left;
    color: #181B01;
  }

  .ant-radio-wrapper {
    width: 552px;
    // height: 44px;
    padding: 12px 20px;
    gap: 12px;
    border-radius: 4px;
    border: 1px #F1F1F1 solid;

    .ant-radio-inner::after {
      background-color: #43490C !important;
    }


  }
}