.yaraa-tabs {
  padding-block: 20px;
  border-bottom: 1px solid rgba(65, 66, 62, 0.1019607843);
  margin-bottom: 30px;
  background: white;
  padding-left: 20px;
  padding-right: 20px;

  &.ant-tabs-left {
    .ant-tabs-tab-btn {
      text-align: left;
      margin: 0;
    }

    div.ant-tabs-content-holder {
      border-left: none;
    }
  }

  .ant-tabs-nav {
    // padding-block: 20px;
    // border-bottom: 1px solid #41423E1A;
    // margin-bottom: 30px;
    // background: white;
    // padding-left: 20px;
    // padding-right: 20px;


    &::before {
      border: none;
    }
  }

  &.bordered {
    .ant-tabs-nav {
      padding: 12px;
      border: 1px solid #f5f5f5;
      border-radius: 12px;
      background: white;
      height: 44px;
    }
  }

  .ant .ant-tabs-tab {
    padding: 8px 10px;

    &-active {
      background: #E6FF00;
      border-radius: 25px;
      animation: transition-background 0.05s ease-in-out;
      padding: 12px 20px;
    }

    +.ant-tabs-tab {
      margin-left: 1rem;
      margin: 0;
    }

    &-btn {
      margin: 0 auto;
      padding: 0px;
      color: #475467;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .ant-tabs-ink-bar {
    background-color: transparent;
  }

  .techrevolve-tab-title {
    // font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.3px;
    padding: 0px;
    letter-spacing: 0em;
    color: #181B01;
    transition: all 0.05s ease-in-out;
  }
}

.techrevolve-tab-count {
  font-size: 0.8rem;
  border-radius: 1.5rem;
  padding: 2px 6px;
  font-family: Poppins;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  margin-left: 6px;
  line-height: 1rem;
  color: white;
  min-width: 1rem;
  background: linear-gradient(180deg, #10b2b4 35.61%, #0970aa 209.65%);
}

.techrevolve-tabs.ant-tabs-top {
  & .ant-tabs-nav-wrap {
    width: 200px;
  }

  & .ant-tabs-nav-more {
    width: 38px;
    height: 38px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      height: unset;
      content: "...";
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      transform: translateY(-9px);
    }
  }
}

@keyframes transition-background {
  0% {
    background: linear-gradient(180deg,
        rgba(16, 178, 180, 0) 35.61%,
        rgba(9, 112, 170, 0) 209.65%);
  }

  100% {
    background: linear-gradient(180deg,
        rgba(16, 178, 180, 0.12) 35.61%,
        rgba(9, 112, 170, 0.12) 209.65%);
  }
}

.ant .ant-tabs-tab {
  padding: 8px 10px;

  &-active {
    background: #E6FF00;
    border-radius: 25px;
    animation: transition-background 0.05s ease-in-out;
    padding: 12px 20px;
  }

  +.ant-tabs-tab {
    margin: 0;
    margin-left: 1rem;
  }

  &-btn {
    margin: 0 auto;
    padding: 0px;
    color: #475467;
    font-size: 14px;
    font-weight: 500;
  }
}

:where(.css-dev-only-do-not-override-e65l43).ant-tabs-top>.ant-tabs-nav,
:where(.css-dev-only-do-not-override-e65l43).ant-tabs-bottom>.ant-tabs-nav,
:where(.css-dev-only-do-not-override-e65l43).ant-tabs-top>div>.ant-tabs-nav,
:where(.css-dev-only-do-not-override-e65l43).ant-tabs-bottom>div>.ant-tabs-nav {
  margin: 0 !important;
}