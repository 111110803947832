//Input
.input {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  padding: 12px 10px;
  gap: 10px;
  color: rgba(28, 31, 0, 1);
  background: inherit;
}

.input:focus {
  border-color: rgba(30, 30, 30, 0.4);
  box-shadow: 0 0 0 2px rgba(241, 241, 241, 0.3);
  outline: none;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 80px;
  gap: 16px;
}

.labelInputContainer {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(30, 30, 30, 0.4);
}

//Checkbox
.checkbox-container {
  // width: 150px;
  height: 20px;
  gap: 8px;
}

.checkbox-label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 16px;
}

.ant-checkbox-inner {
  border-color: rgba(30, 30, 30, 0.4) !important;
  background-color: white !important;
}

.ant-checkbox-inner:after {
  border-color: rgba(30, 30, 30, 0.4) !important;
}

.ant-radio-inner {
  border-color: rgba(30, 30, 30, 0.4) !important;
  background-color: #fff !important;
}

.ant-radio-inner::after {
  background-color: rgba(30, 30, 30, 0.4) !important;
  transform: scale(0.475) !important;
}

.ant-radio-checked::after {
  border-color: rgba(30, 30, 30, 0.4) !important;
}

// .ant-checkbox-wrapper {
//   display: flex;
//   align-items: center;
//   gap: 8px !important;

//   .ant-form input,
//   :where(.css-dev-only-do-not-override-1t9why8) {
//     width: 19px;
//     height: 19px;
//     padding: 2.38px 0px 0px 0px;
//     gap: 0px;
//     opacity: 0px;
//     color: #181B01;

//   }
// }

// .ant-form input {
//   // width: 19px;
//   // height: 19px;
//   // padding: 2.38px 0px 0px 0px;
//   // gap: 0px;
//   // opacity: 0px;
//   width: 14.25px;
//   height: 14.25px;
//   top: 2.38px;
//   left: 2.38px;
//   gap: 0px;

//   color: #181B01;

// }

// .ant-form input[type="checkbox"]:checked {
//   color: #181B01 !important;

// }

//CompleteProfile
.parentContainerCP {
  position: absolute;
  width: 64%;
  height: 2100px;
  top: 148px;
  left: 30%;
  gap: 80px;
  display: flex;
  flex-direction: column;
}

.profileHeader {
  display: flex;
  width: 100%;
  height: 166px;
  padding: 8px 0 8px 0;
  gap: 32px;
  align-items: center;
  flex-direction: column;
}

.profileHeaderContainer {
  width: 179px;
  height: 150px;
  gap: 1px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.pictureFrame {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: rgba(28, 31, 0, 1);
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.pictureFrameText {
  color: white;
  font-family: Inter;
  font-weight: 500;
  font-size: 18px;
  line-height: 21.78px;
  display: flex;
  padding-top: 30px;
}

.pfCaption {
  display: flex;
  flex-direction: column;
  width: 179px;
  height: 52px;
  gap: 5px;
}

.pfcName {
  color: rgba(28, 31, 0, 1);
  font-family: Inter;
  font-weight: 600;
  font-size: 19px;
  line-height: 24.2px;
}

.pfcLocation {
  color: rgba(131, 138, 148, 1);
  font-family: Inter;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}

.editProfile {
  border-radius: 33px;
  padding: 8px 16px;
  background-color: rgba(230, 255, 0, 1);
  position: absolute;
  top: 160px;
  right: 43.5%;
}

//Personal Information
.personalInfoForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.personalInfo {
  width: 100%;
  height: 636px;
  display: flex;
  flex-direction: row;
  gap: 100px;
}

.piPrimary {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.piHeadText {
  font-family: inter;
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
  color: rgba(28, 31, 0, 1);
}

.piBodyText {
  color: rgba(131, 138, 148, 1);
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-family: inter;
}

.piSecondary {
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.flNameContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.edInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 40%;
}

.ant-checkbox-group {
  // display: none;
}

.radioButtonA {
  display: flex;
  flex-direction: column;
  gap: 10px;

  span {
    color: #181B01;
  }
}

.radioLabel {
  color: rgba(131, 138, 148, 1);
  width: 160px;
}

.education {
  width: 100%;
  height: 290px;
  display: flex;
  flex-direction: row;
  gap: 100px;
}

.others {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: row;
  gap: 24%;
}

.edInfoContain {
  display: flex;
  flex-direction: row;
  gap: 40%;
}

.devices {
  width: 100%;
  height: 395px;
  display: flex;
  flex-direction: row;
  gap: 24%;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  width: 192px;
  height: 43px;
  gap: 15px;
  padding-left: 70%;
}

.cancel {
  border-radius: 33px;
  padding: 8px 16px;
  background-color: rgba(241, 241, 241, 1);
}

.cancel:hover,
.cancel:active {
  background-color: rgba(230, 255, 0, 1);
}

@media (max-width: 1350px) {

  .others,
  .devices {
    gap: 21%;
  }

  @media (max-width: 1300px) {

    .others,
    .devices {
      gap: 18%;
    }

    @media (max-width: 1250px) {

      .others,
      .devices {
        gap: 15%;
      }

      @media (max-width: 1200px) {

        .others,
        .devices {
          gap: 12%;
        }

        @media (max-width: 1150px) {

          .others,
          .devices {
            gap: 9%;
          }

          @media (max-width: 1100px) {

            .others,
            .devices {
              gap: 6%;
            }

            @media (max-width: 1023px) {
              .parentContainerCP {
                position: unset;
                width: 100%;
              }

              .others,
              .devices {
                gap: 27%;
              }

              .editProfile {
                top: 265px;
                right: 45%;
                padding: 5px 10px;
              }

              @media (max-width: 975px) {

                .others,
                .devices {
                  gap: 24%;
                }

                @media (max-width: 925px) {

                  .others,
                  .devices {
                    gap: 20%;
                  }

                  @media (max-width: 875px) {

                    .others,
                    .devices {
                      gap: 16%;
                    }

                    @media (max-width: 825px) {

                      .others,
                      .devices {
                        gap: 12%;
                      }

                      @media (max-width: 775px) {

                        .others,
                        .devices {
                          gap: 8%;
                        }

                        .parentContainerCP {
                          height: 3200px;
                        }

                        .editProfile {
                          right: 44%;
                        }

                        .personalInfo,
                        .education,
                        .others,
                        .devices {
                          flex-direction: column;
                          padding: 0 7% 0 10%;
                          gap: 30px;
                        }

                        .personalInfo {
                          height: 735px;
                        }

                        .education {
                          height: 395px;
                        }

                        .others {
                          height: 480px;
                        }

                        .edInfoContainer {
                          gap: 35%;
                        }

                        .edInfoContain {
                          gap: 25%;
                        }

                        .devices {
                          height: 490px;
                        }

                        .buttonContainer {
                          padding-left: 60%;
                        }

                        @media (max-width: 635px) {

                          .personalInfo,
                          .education,
                          .others,
                          .devices {
                            padding: 0 4%;
                          }

                          .editProfile {
                            right: 42%;
                          }

                          .flNameContainer {
                            gap: 10%;
                          }

                          .edInfoContainer {
                            gap: 30%;
                          }

                          .inputContainer {
                            width: 100%;
                          }

                          .labelInputContainer {
                            font-size: 15px;
                          }

                          .input {
                            width: 100%;
                            height: 45px;
                          }

                          @media (max-width: 520px) {
                            .edInfoContainer {
                              gap: 26%;
                            }

                            @media (max-width: 432px) {

                              .personalInfo,
                              .education,
                              .others,
                              .devices {
                                padding: 0;
                              }

                              .parentContainerCP {
                                height: 2950px;
                              }

                              .edInfoContainer,
                              .edInfoContain {
                                gap: 17%;
                              }

                              .buttonContainer {
                                padding-left: 50%;
                              }

                              .editProfile {
                                right: 38%;
                              }

                              .flNameContainer {
                                gap: 20px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}