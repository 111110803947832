.ant-table-wrapper {
  .ant-table {
    .ant-table-container {
      border-start-start-radius: 12px;
      border-start-end-radius: 12px;

      .ant-table-content {
        overflow: auto hidden;

        table {
          width: max-content;
          min-width: 100%;
          table-layout: auto;
        }
      }

      .ant-dropdown-menu.ant-dropdown-menu-root {
        max-height: 10rem;
        overflow-y: auto;
      }

      background: #fff;

      .ant-table-thead {
        tr {
          th.ant-table-cell {

            background: #E4E7EC !important;
            color: var(--Neutrals-neutrals-8, #595959);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 145%;
            /* 17.4px */
            // letter-spacing: -0.06px;
            padding: 12px 24px !important;


            &::before {
              display: none !important;
            }
          }

          td.ant-table-cell {
            background: #E4E7EC !important;
            color: var(--Neutrals-neutrals-8, #595959);
            /* Paragraph/XSmall/Medium */
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 145%;
            /* 17.4px */
            letter-spacing: -0.06px;
          }
        }
      }

      .ant-table-tbody {
        .ant-table-row {
          .ant-table-cell {
            &:first-child {
              display: flex;
              height: 72px;
              padding: 16px 24px;
              align-items: center;
              gap: 12px;
              align-self: stretch;
              color: var(--Neutrals-neutrals-9, #454545);
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 145%;
            }

            &:nth-child(2),
            &:nth-child(3) {
              height: 72px;
              padding: 16px 24px !important;
              gap: 12px;
              color: var(--Neutrals-neutrals-9, #454545);
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 145%;
            }

            // &:nth-child(2) {
            //   padding-left: 0 !important;
            // }
            // &:nth-child(3) {
            //   padding-left: 0 !important;
            // }

            &:nth-child(4) {
              color: var(--Default-Body-Color, #181B01);
              font-feature-settings: 'cv04' on, 'cv03' on;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 145%;
              /* 20.3px */
            }
          }
        }
      }
    }
  }
}

.ant-picker {
  border-radius: 25px !important;
  border: 1px solid var(--Neutrals-neutrals-5, #D9D9D9) !important;
  padding: 10px 12px;



  .ant-picker-input {

    display: flex;
    // padding: 10px 12px;
    align-items: center;
    gap: 8px;
    position: relative;

    input {
      order: 2;
      max-width: 83px !important;

    }

    .ant-picker-suffix {
      order: 1;
      color: rgba(24, 27, 1, 0.6);
    }

    .ant-picker-clear {
      // margin-left: auto !important;
      // order: 1 !important;
      // position: absolute;
      display: none !important;
    }
  }
}

.ant-select {
  // width: 103px !important;
  height: 38px !important;


  .ant-select-selector {
    border-radius: 25px;
    border: 1px solid var(--Neutrals-neutrals-5, #D9D9D9);
    padding: 9px 12px !important;


  }
}