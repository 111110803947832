.ant-modal-content {
  position: relative;
  // top: 100px !important;
  top: 0 !important;
  background-color: #fff;

  border: 0;
  border-radius: 21px !important;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 40px !important;
  // overflow: hidden !important;

}

.ant-modal-close {
  width: 40px !important;
  height: 40px !important;
  margin-right: 20px !important;
  margin-top: 10px !important;
  border-radius: 20px !important;
}

.ant-modal-wrap {
  // overflow: hidden !important;
}